/*
 * @Author: mulingyuer
 * @Date: 2023-02-07 16:49:15
 * @LastEditTime: 2023-02-07 19:43:59
 * @LastEditors: mulingyuer
 * @Description: 表格工具函数
 * @FilePath: \admin_site\src\utils\excelTool.js
 * 怎么可能会有bug！！！
 */
import * as XLSX from "xlsx";

/** 读取file对象转为二维数组 */
export function readExcel(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function() {
      const result = reader.result;
      const workbook = XLSX.read(result, {
        type: "binary",
      });
      //处理数据
      const data = {}; //{key:子表名，value:子表数据}
      const sheetNames = workbook.SheetNames;
      sheetNames.forEach((sheetName) => {
        const ws = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
        data[sheetName] = ws;
      });
      resolve(data);
    };

    reader.onerror = function() {
      reject(reader.error);
    };

    //读取文件
    reader.readAsBinaryString(file);
  });
}
